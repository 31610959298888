import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import '../i18n';
import Logo from '../images/charity-logo.svg'
import '../styles/landingPage/landing.css';
import { LanguageCircleIcon } from 'hugeicons-react';
import {ArrowDown01Icon } from 'hugeicons-react';

function LandingPage() {
  const [isLangOpen, setIsLangOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') || 'en');
    const { t, i18n } = useTranslation();
    useEffect(() => {
        i18n.changeLanguage(localStorage.getItem("selectedLanguage") || "en");
      }, [i18n]);

      const changeLanguage = (lng) => {
        localStorage.setItem('selectedLanguage', lng);
        setSelectedLanguage(lng);
        i18n.changeLanguage(lng);
        setIsLangOpen(false);
      };
    
      useEffect(() => {
        i18n.changeLanguage(localStorage.getItem('selectedLanguage') || 'en');
      }, [i18n]);
    
  return (
    <>
        <div className="landing-page">
            <div className="container">
                <div className="image-container">
                    <img src={Logo} alt="logo" />
                </div>
                <div className="text-container">
                    <h1>{t("landingPage.welcome")}</h1>
                    <p>{t("landingPage.paragraphText")}</p>

                </div>
                <div className="buttons">
                    <a href="/home" className="button">{t("landingPage.buttonText")}</a>
                    <a href="https://www.charityhelpinghands.org/" className="button" target='_blank'>{t("landingPage.buttonTextTwo")}</a>
                </div>
                <div className="info">
                <div className="general-inquirie">
                <div className="icon-inquirie">
                  <img src="/cardIcons/heart.svg" alt="heart icon" />
                </div>
                <div className="heading2">
                  {t("homePage.aboutUsSection.icontext")}
                </div>
              </div>
              <div className="divider"></div>
              <div className="info-inquirie">
                <div className="paragraph">
                  {t("homePage.aboutUsSection.call")}: +1-819-679-1001
                </div>
                <div className="paragraph">
                  {t("homePage.aboutUsSection.email")}: info@charityhelpinghands.org
                  
                </div>
              </div>
                </div>

                <div className="language-selector">
                  <button onClick={() => setIsLangOpen(!isLangOpen)} className="language-button">
                    <span className="language-icon">
                      <LanguageCircleIcon size={24} variant={"stroke"} />
                    </span>
                    <span>{selectedLanguage === 'en' ? 'English' : 'Français'}</span>
                    <span className="arrow-icon"><ArrowDown01Icon/></span>
                  </button>
                  {isLangOpen && (
                    <div className="language-dropdown">
                      <button onClick={() => changeLanguage('en')}>English</button>
                      <button onClick={() => changeLanguage('fr')}>Français</button>
                    </div>
                  )}
                </div>
                
                <div className="circle-decoration left">
                  <div className="circle-decoration-small"></div>
                </div>
          <div className="circle-decoration right">
          <div className="circle-decoration-small"></div>
          </div>
          
            </div>
            
        </div>
    </>
  )
}

export default LandingPage