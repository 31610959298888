export const frenchFaqs = [
    {
        "question": "Ce don est-il déductible des impôts ?",
        "answer": "Oui, l'association est enregistrée en tant qu'organisation caritative. Le numéro d'immatriculation est 783872062 RR 0001"
    },
    {
        "question": "Puis-je faire un don avec PayPal ?",
        "answer": "Oui, vous pouvez faire un don directement sur le site web."
    },
    {
        "question": "Est-ce que je recevrai un reçu ?",
        "answer": "Oui est une copie du reçu sera envoyée à l'adresse courriel que vous avez fournie lors de votre don. Si vous faites un don mensuel récurrent, un reçu vous sera envoyé à votre adresse courriel chaque mois après le traitement de votre don."
    },
    {
        "question": "Puis-je dédier mon don à quelqu'un ?",
        "answer": "Oui, vous pouvez dédier votre don en l'honneur ou à la mémoire d'un être cher. Voir la case à cocher « Dédier mon don en l'honneur/à la mémoire de » et remplissez les informations. Suivez les étapes et entrez vos coordonnées et un message vous sera automatiquement envoyé une fois la transaction finalisée."
    },
    {
        "question": "Comment puis-je spécifier un montant de don qui n'est pas déjà répertorié ?",
        "answer": "Indiquez le montant de votre don en cliquant simplement sur « autre » dans la page du don et entrez votre don."
    },
    {
        "question": "Comment mon don apparaîtra-t-il sur mon relevé de carte de crédit ?",
        "answer": "Votre don apparaîtra sur votre relevé de carte de crédit sous la mention Charity Helping Hands Association inc."
    },
    {
        "question": "Puis-je faire un don par chèque à la place ?",
        "answer": "Oui, vous pouvez faire votre chèque à l'ordre de Charity Helping Hands Association Inc et l'envoyer par la poste à l'attention de Charity Feh 4535 rue Taschereau, Sherbrooke, QC, J1L 0E6, Québec, Canada."
    },
    {
        "question": "Comment puis-je faire un don à l'association Charity Helping Hands ?",
        "answer": "Chèque, PayPal, virement bancaire"
    },
    {
        "question": "Mon don est-il sécurisé ?",
        "answer": "Absolument. Nous prenons très au sérieux la sécurité et la confidentialité de vos informations. Nous fournissons un environnement sûr et sécurisé pour les dons en ligne. Vos informations personnelles ne seront pas vendues, échangées ou partagées avec qui que ce soit d'autre."
    },
    {
        "question": "Comment puis-je vous contacter ?",
        "answer": "Veuillez nous envoyer un e-mail au info@charityhelpinghands.org ou appelez-nous au +1 819 679 1001"
    },
    {
        "question": "Quels sont les frais associés à mon don ?",
        "answer": "Pour chaque transaction par carte de crédit, il y a des frais de transaction de 2,2 % + 0,30 $ pour Visa, Mastercard et Discover ; des frais supplémentaires de 1 % pour American Express ; Il y a des frais supplémentaires de 1% pour les paiements internationaux. Il y a des frais Classy de 2% sur toutes les transactions"
    },
    {
        "question": "Puis-je mettre à jour un don mensuel récurrent ?",
        "answer": "Oui! Vous pouvez gérer votre don mensuel récurrent en ligne. Si vous avez des questions, contactez-info@charityhelpinghands.org ou appelez le +1 819 679 1001"
    }
]
