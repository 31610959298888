import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const SpecialEventForm = () => {
  return (
    <>
      <Helmet>
        <script
          type="text/javascript"
          src="https://form.jotform.com/242671666756267"
          async
        ></script>
      </Helmet>
    </>
  );
};

export default SpecialEventForm;

