export const englishFaqs = [
    {
        "question": "Is this donation tax deductible?",
        "answer": "Yes, the association is registered as a charity organization. The registration number is 783872062 RR 000"
    },
    {
        "question": "Can I donate using PayPal?",
        "answer": "Yes, you can donate directly on the website."
    },
    {
        "question": "Will I get a receipt?",
        "answer": "Yes, a copy of the receipt will be sent to the email address you provided while making your donation. If you are making a monthly recurring donation, a receipt will be sent to your email address each month after your donation is processed."
    },
    {
        "question": "Can I dedicate my donation to someone?",
        "answer": "Yes, you can dedicate your donation in honor or in memory of a loved one. See the “Dedicate my donation in honor/memory of” checkbox and fill in the information. Follow the steps and input your contact information and a message will be automatically sent to you once the transaction is finalized."
    },
    {
        "question": "How do I specify a donation that is not already listed?",
        "answer": "Indicate your donation amount simply by clicking “other” in the donation page and enter your donation."
    },
    {
        "question": "How will my donation appear on my credit card statement?",
        "answer": "Your donation will appear on your credit card statement as Charity Helping Hands Association inc."
    },
    {
        "question": "Can I donate by check instead?",
        "answer": "Yes, you can make your check out to Charity Helping Hands Association Inc and mail to attn. Charity Feh 4535 Taschereau street, Sherbrooke, QC, J1L 0E6, Quebec, Canada."
    },
    {
        "question": "How else can I make a gift to Charity Helping Hands Association?",
        "answer": "Check, PayPal, bank transfer"
    },
    {
        "question": "Is my donation secure?",
        "answer": "Absolutely. We take the security and privacy of your information very seriously. We provide a safe and secure environment for online donations. Your personal information will not be sold, traded or shared with anyone else."
    },
    {
        "question": "How can I contact you?",
        "answer": "Please email us at info@charityhelpinghands.org or call us at +1 819 679 1001"
    },
    {
        "question": "What are the costs associated with my donation?",
        "answer": "For each credit card transaction, there is a transaction fee of 2.2% + $0.30 for Visa, Mastercard, and Discover; an additional 1% American Express fee; There is an additional 1% fee for international payments. There is a 2% Classy fee on all transactions"
    },
    {
        "question": "Can I update a monthly recurring donation?",
        "answer": "Yes! You can manage your recurring monthly donation online. If you have any questions, contact info@charityhelpinghands.org or call +1 819 679 1001"
    }
]
