import React, { useState } from 'react';
import '../componentStyles/eventRegistration/eventRegistration.css';
import axios from 'axios';
import { API_URL } from '../constants';
import { ArrowRight01Icon, ArrowRight02Icon, Loading02Icon } from 'hugeicons-react';
import { useTranslation } from 'react-i18next';
const EventRegistrationForm = ({ event, eventContent, toggleRegistrationForm }) => {
  const { t, i18n } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [fullName, setFullName] = useState("")
  const [email, setEmail] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [gender, setGender] = useState("")
  const [ageGroup, setAgeGroup] = useState("")
  const [address, setAddress] = useState("")
  const [sourceOfInfo, setSourceOfInfo] = useState("")
  const [additionalInfo, setAdditionalInfo] = useState("")
  const [ageRange, setAgeRange] = useState("")

  const handleSubmit = async () => {
    if (!fullName || !email || !phoneNumber || !gender || !address || !sourceOfInfo || !additionalInfo) {
      setError('All fields are required');
      setTimeout(() => { setError("") }, 2000)
      return;
    }

    const registrationData = {
      "name": fullName,
      "email": email,
      "phone_number": phoneNumber,
      "age_group": ageGroup,
      "gender": gender,
      "address": address,
      "source_of_info": sourceOfInfo,
      "additional_info": additionalInfo,
      "age_range": ageRange
    }
    try {
      setError("")
      setMessage("")
      setIsLoading(true)
      console.log(registrationData)
      const response = await axios.post(`${API_URL}/events/${event.slug}/registrations/new/`, registrationData);
      if (response.status === 201) {

        setIsLoading(false)
        setMessage("Registration successful! Check your email for confirmation.")
        setTimeout(() => {
          window.location.href = '/'
        }, 10000);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      if (error.response) {
        setError(error.response.data.error || error.response.data.message || "Error submitting form, please try again later.");

      } else {
        setError("Error submitting form, please try again later.");
      }
      setIsLoading(false)
    }

  };

  return (
    <div className="event-registration-form">
      {event
        ? <>
          <h2>{t("EventRegistrationForm.title")} '{event.title || eventContent.title}'</h2>
          <p>{t("EventRegistrationForm.description")}</p>
          <form >
            <input
              type="text"
              name="name"
              placeholder={t("EventRegistrationForm.fullName")}
              onChange={(e) => setFullName(e.target.value)} value={fullName}
            />

            <div className="form-row">
              <input
                type="email"
                name="email"
                placeholder={t("EventRegistrationForm.email")}
                onChange={(e) => setEmail(e.target.value)} value={email}
              />
              <input
                type="tel"
                name="phone_number"
                placeholder={t("EventRegistrationForm.phone")}
                onChange={(e) => setPhoneNumber(e.target.value)} value={phoneNumber}
              />

            </div>
            <div className="form-row">
              <input
                type="text"
                name="address"
                placeholder={t("EventRegistrationForm.address")}
                onChange={(e) => setAddress(e.target.value)} value={address}
              />
              <select
                name="gender" onChange={(e) => setGender(e.target.value)} value={gender}
              >
                <option value="">{t("EventRegistrationForm.selectGender")}</option>
                <option value="male">{t("EventRegistrationForm.male")}</option>
                <option value="female">{t("EventRegistrationForm.female")}</option>
                <option value="other">{t("EventRegistrationForm.other")}</option>
              </select>

            </div>
            <select value={ageRange} name="" id="" onChange={(e) => setAgeRange(e.target.value)} defaultValue={ageRange}>
              <option value="">{t("EventRegistrationForm.selectAgeRange")}</option>
              <option value="13-17">13-17</option>
              <option value="18-25">18-25</option>
              <option value="26-35">26-35</option>
              <option value="36-45">36-45</option>
              <option value={t("EventRegistrationForm.range")}>{t("EventRegistrationForm.range")}</option>

            </select>
            <div className="form-row">
              <select className=''
                onChange={(e) => setAgeGroup(e.target.value)} value={setAgeGroup}
              >
                <option value="">{t("EventRegistrationForm.selectAgeGroup")}</option>
                <option value={"Youth"} >{t("EventRegistrationForm.youth")}</option>
                <option value={"Adult"}>{t("EventRegistrationForm.adult")}</option>
              </select>
              <select
                name="source_of_info"
                onChange={(e) => setSourceOfInfo(e.target.value)} value={sourceOfInfo}
              >
                <option value="">{t("EventRegistrationForm.sourceOfInfo")}</option>
                <option value="social">{t("EventRegistrationForm.socialMedia")}</option>
                <option value="friend">{t("EventRegistrationForm.friend")}</option>
                <option value="advertisement">{t("EventRegistrationForm.advertisement")}</option>
              </select>
            </div>
            <textarea
              name="additional_info"
              placeholder={t("EventRegistrationForm.additionInfo")}
              onChange={(e) => setAdditionalInfo(e.target.value)} value={additionalInfo}
            />
            {error && <div className='error-message'>{error}</div>}
            {message && <div className='success-message'>{message}</div>}

          </form>
          <div className="buttons">
            <button onClick={toggleRegistrationForm} type="button" className="cancel-btn">{t("EventRegistrationForm.cancel")}</button>
            <button onClick={handleSubmit} type="submit" className="submit-btn">
              {
                isLoading
                  ? <Loading02Icon size={18} className='loading-icon' />
                  :
                  <>
                    <span>{t("EventRegistrationForm.buttonText")}</span>
                    <ArrowRight01Icon />
                  </>
              }
            </button>
          </div>
        </>
        : 'Can not load this'
      }
    </div>
  );
};

export default EventRegistrationForm;