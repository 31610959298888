import React from "react";
import "../../componentStyles/productCard/product.css";

const ProductCard = ({ image, name, price, link, specialStyle }) => {
  return (
    <div
      className={`product-card-container ${specialStyle ? 'special-card' : ''}`}
      onClick={() => { window.open(link, '_blank'); }}
      style={{ cursor: 'pointer' }}
    >
      <div className="product-card">
        <div className="img-container">
          <img src={image} alt={name} className="product-image" />
        </div>
        <div className="product-info">
          <div className="product-name">{name}</div>
          <div className="price">Price: ${price}</div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
