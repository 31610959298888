import React, { useState } from "react";
import "../componentStyles/faqsCard/faqsCard.css";

const FaqsCard = ({ number, question, answer, isOpen, onToggle }) => {
  return (
    <>
      <div className="faqs-card-container">
      <div className="faqs-card">
        <div className="question-header">
          <div className="number-and-question">
            <span className="number">{number}</span>
            <h3 className="question">{question}</h3>
          </div>
          <div className="plus-and-minus" onClick={onToggle}>
            {isOpen ? (
              <img src="/cardIcons/minus.svg" alt="minus icon" />
            ) : (
              <img src="/cardIcons/plus.svg" alt="plus icon" />
            )}
          </div>
        </div>
        {isOpen && <p className="answer">{answer}</p>}
      </div>
    </div>
    </>
  );
};

export default FaqsCard;
