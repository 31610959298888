import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import PageHeader from '../../../componets/header'
import Footer from '../../../componets/footer'
import axios from 'axios'
import { API_URL } from '../../../constants'
import { ArrowRight01Icon, Calendar01Icon, Calendar02Icon, CalendarRemove01Icon, Location01Icon } from 'hugeicons-react'

import '../../../styles/eventDetails/eventDetails.css'
import EventRegistrationForm from '../../../componets/eventRegistration'
import { useTranslation } from 'react-i18next'
const EventDetails = () => {
    const { t, i18n } = useTranslation()
    const { eventSlug } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [event, setEvent] = useState({})
    const [frenchContent, setFrenchContent] = useState([])
    const [englishContent, setEnglishContent] = useState([])
    const [eventContent, setEventContent] = useState({})
    const [showRegistrationForm, setShowRegistrationForm] = useState(false)

    const toggleRegistrationForm = () => {
        setShowRegistrationForm(!showRegistrationForm)
    }

    // take a string of date like this: "2024-09-21T22:00:00Z" and convert to 24 January 2024 for example

    const formaDate = (dateString) => {
        const date = new Date(dateString)
        const options = { year: 'numeric', month: 'numeric', day: 'numeric' }
        return date.toLocaleDateString('en-US', options)
    }

    useEffect(() => {
        if (localStorage.getItem('selectedLanguage') === 'en') {
            setEventContent(englishContent)
        } else {
            setEventContent(frenchContent)
        }
    })

    useEffect(() => {
        const getEventDetails = async () => {
            try {
                const response = await axios.get(`${API_URL}/events/${eventSlug}/`)
                let french = {}
                let english = {}
                if (response.status === 200) {
                    setIsLoading(false)
                    setEvent(response.data)

                    const event = response.data
                    console.log(event.start_date)
                    event.content.forEach((content) => {
                        // new event object
                        const eventObj = {
                            title: content.title,
                            language: content.lang,
                            description: content.description,
                        }
                        console.log(eventObj)
                        if (content.lang === 'English') english = (eventObj)
                        else if (content.lang === 'French') french = (eventObj)
                    })
                    // console.log(response.data)
                    setFrenchContent(french)
                    setEnglishContent(english)
                }
            } catch (error) {
                console.log(error)
                setIsLoading(false)
            }
        }
        getEventDetails()
    }, [])
    return (
        <div className='event-details'>
            <PageHeader />
            <div className="container">
                {
                    isLoading ?
                        <div>Loading...</div>
                        :
                        <>
                            {showRegistrationForm
                                ? <EventRegistrationForm event={event} eventContent={eventContent} toggleRegistrationForm={toggleRegistrationForm} /> :
                                <>
                                    <div className='event-details-container'>
                                        <div className="title-image">
                                            <h3>{eventContent.title}</h3>
                                            <small>{t("eventDetailsPage.addedOn")} {formaDate(event.created_at)}</small>

                                            {
                                                event.id === 8
                                                    ? <img src="/images/conference.jpg" alt="" className="event-image" />


                                                    : event.image
                                                        ? <img className='event-image' src={event.image} alt={event.title} />
                                                        : <div className="image-placeholder">
                                                            <img src="/logo192.png" alt="" />
                                                        </div>
                                            }
                                        </div>

                                        <div className="details">
                                            <h4>{t("eventDetailsPage.eventDetails")}</h4>

                                            <div className="time-location">
                                                <div className="start-date">
                                                    <Calendar02Icon />
                                                    <div className="text">
                                                        <small>{t("eventDetailsPage.startDate")}</small>
                                                        <p className="date">{formaDate(event.start_date)}</p>
                                                    </div>
                                                </div>

                                                <div className="end-date">
                                                    <Calendar02Icon />
                                                    <div className="text">
                                                        <small>{t("eventDetailsPage.endDate")}</small>
                                                        <p className="date">{formaDate(event.end_date)}</p>
                                                    </div>
                                                </div>

                                                <div className="location">
                                                    <Location01Icon />
                                                    <div className="text">
                                                        <small>{t("eventDetailsPage.location")}</small>
                                                        <p className="location">{event.location}</p>
                                                    </div>
                                                </div>
                                            </div>

                                            <p>{eventContent.description}</p>
                                            {
                                                event.id === 7 ? <Link to={`/back-to-school-bbq-event/`} className="button primary-button">{t("eventDetailsPage.buttonText")}</Link>

                                                    : event.id === 8 ?
                                                        <>
                                                            <Link to={'https://www.linkedin.com/posts/charity-helping-hands-association-inc_soutiencommunautaire-avenirinclusif-sensibilisationsantaezmentale-activity-7244354480886964225-c1jU?utm_source=share&utm_medium=member_desktop'} target='blank'>{t('watchVideo')}</Link>
                                                            <Link target="blank" to={'https://form.jotform.com/242671666756267'} className="button primary-button" >{t("eventDetailsPage.buttonText")}</Link>
                                                        </>
                                                        : <button onClick={setShowRegistrationForm} className="primary-button">
                                                            <span>{t("eventDetailsPage.buttonText")}</span>
                                                            <ArrowRight01Icon />
                                                        </button>
                                            }
                                        </div>
                                    </div>

                                    {/* <div className="more-events">
                                        <h3>Other Events</h3>

                                    </div> */}
                                </>
                            }
                        </>

                }
            </div>
            <Footer />
        </div>
    )
}

export default EventDetails
