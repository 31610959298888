import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import '../i18n'
import { Location01Icon } from 'hugeicons-react';
import { Mail01Icon } from 'hugeicons-react';
import { CallRinging02Icon  } from 'hugeicons-react';
import SubscriptionForm from "./subscriptionForm";
import "../footer/footer.css"
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Footer = () => {

  const handleLinkClick = (sectionId) => {
    
    if (sectionId === 'programs' || sectionId === 'contact') {
      navigate('/' + sectionId);
    } else {
      if (window.location.pathname !== '/') {
        navigate('/#' + sectionId);
      } else {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        } else {
          window.location.hash = sectionId;
        }
      }
    }
  };
  const { t, i18n } = useTranslation()
  const changeLanguage = (lng) => {
      localStorage.setItem('selectedLanguage', lng)
  };

  const navigate = useNavigate();

    const handleViewDetails = (program) => {
        navigate(`/programs/${program.id}`, { state: program });
      }

  useEffect(() => {
      i18n.changeLanguage(localStorage.getItem('selectedLanguage') || 'en');
  }, [i18n]);

  const programsData = [
    {
      id: 1,
      title: t('homePage.ourProgramsSection.programTitle'),
      description: t('homePage.ourProgramsSection.programDescription'),
      progress: '24%'
    },
    {
      id: 2,
      title: t('homePage.ourProgramsSection.programTwoTitle'),
      description: t('homePage.ourProgramsSection.programTwoDescription'),
      progress: '10%'
    }]
  return (
    <div className="sub">
       <SubscriptionForm/>
    <div className='footer-main'>
   
      <div className="img-text">
        <div className="location-call">
          <div className="icon-text">
            <div className="icon-footer">
            <Location01Icon 
      size={20} 
      color={"#9B51E0"}
      variant={"stroke"}
    />
            </div>
            <div className="actual-text">
              <p>{t("homePage.footerSection.location")}</p>
              <h2>4535, rue Taschereau <br />Sherbrooke, Québec <br />CANADA J1L 0E6</h2>
            </div>
          </div>
        <div className="icon-text">
        <div className="icon-footer">
        <Mail01Icon 
      size={20} 
      color={"#9B51E0"}
      variant={"stroke"}
    />
          </div>
          <div className="actual-text">
              <p>{t("homePage.footerSection.email")}</p>
              <h2 className="email">info@charityhelpinghands.org</h2>
            </div>
        </div>
        <div className="icon-text">
        <div className="icon-footer">
          <CallRinging02Icon 
      size={20} 
      color={"#9B51E0"}
      variant={"stroke"}
    />
          </div>
          <div className="actual-text">
              <p>{t("homePage.footerSection.call")}</p>
              <h2>+1 819 679 1001</h2>
            </div>
        </div>
        </div>
      </div>

      <div className="useful-links">
        <h1>{t("homePage.linksSection.title")}</h1>
        <div className="links-text">
          <div className="left-links">
          <Link to={"/"} onClick={() => handleLinkClick('home')}> <p>{t("homePage.linksSection.home")}</p></Link>
           <Link to={"/#about-us"} onClick={() => handleLinkClick('about-us')} > <p>{t("homePage.linksSection.aboutus")}</p></Link>
           <Link to={"/programs"} onClick={() => handleLinkClick('programs')}> <p>{t("homePage.linksSection.programs")}</p></Link>
           
           <Link to={"/contact"} onClick={() => handleLinkClick('contact')}> <p>{t("homePage.linksSection.contact")}</p></Link>
          </div>

          <div className="right-links">
          <Link to={"/#volunteer"} onClick={() => handleLinkClick('volunteer')}> <p>{t("homePage.linksSection.getinvolved")}</p></Link>
          <Link to={"/programs"} onClick={() => handleLinkClick('donate')}> <p>{t("homePage.linksSection.donate")}</p></Link>
          <Link to={"/#our-events"} onClick={() => handleLinkClick('our-events')}> <p>{t("homePage.linksSection.events")}</p></Link>
          <Link to={'/#our-shops'} onClick={() => handleLinkClick('our-shops')}> <p>{t("homePage.linksSection.shop")}</p></Link>
          </div>
        </div>
      </div>

      <div className="programs-footer">
        <h1>{t("homePage.linksSection.programs")}</h1>
        
          {programsData.map((program, index) => (
            <div className="white-part" onClick={() => handleViewDetails(program)}>
              <div className="value" >
              <div className="image-div">
              </div>
              <div className="program-title">
                <div className="title">{program.title}</div>
              </div>
            </div> 
            </div>
              
          ))}
        
      </div>
    </div>
    </div>
  )
}

export default Footer

