import React from "react";
import '../componentStyles/programCard/program.css';
import { ArrowRight02Icon } from "hugeicons-react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProgramCard = ({ title, description, progress, id, onViewDetails, link, image, details }) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const handleViewDetails = () => {
        navigate(`/programs/${id}`, { state: { title, description, progress } });
    };

    return (
        <div className='card'>
            <div className='image-button'>
                <img src={image} alt='program image' />

                <a className='white-btn' id='program1' onClick={onViewDetails}>{t("buttonText.viewDetails")}<ArrowRight02Icon
                    size={24}
                    color={"#000000"}
                    variant={"stroke"}
                    className='icon'
                /></a>
            </div>
            <div className='text'>
                <h4>{title}</h4>
                <p>{description}</p>
            </div>
            {/* <div className='goal-raised'>
          <div className='goal'>
              <p>Goal</p>
              <p>50,000 $</p>
          </div>
          <div className='raised'>
              <p>Raised</p>
              <p>12,000 $</p>
          </div>
      </div>

      <div className="progress-bar-container">
<div className="percentage">{progress}</div>
<div className="progress-bar"><div className="progress" style={{ width: `${progress}` }}></div></div>


</div> */}
            {/* <a href='https://www.charityhelpinghands.org/give-once/' target="_blank" className='donate-btn'>Donate now <ArrowRight02Icon
          size={24}
          color={"#000000"}
          variant={"stroke"}
          className='icon'
      /></a> */}



        </div>
    );
}

export default ProgramCard;