import React from 'react'
import PageHeader from '../../componets/header'
import '../../styles/programPage/program-details.css'
import Footer from '../../componets/footer'
import { useLocation, useParams } from 'react-router-dom';

const ProgramDetails = () => {

    const location = useLocation();
    const { id } = useParams();
    const program = location.state;
    console.log(program.details);
    console.log(program.description);
    return (
        <div className='single-page'>

            <PageHeader />
            <div className='program-details'>
                <div className='programs'>
                    <div className='text'>
                        <h3><span>Stay update</span> with us</h3>
                        <p>Programs</p>
                    </div>
                </div>
                <div className='program-details-layout'>
                    <div className='image'>
                        <img src='/images/charity-img.jpg' alt='program1' />
                    </div>
                    <div className='text'>

                        <h2>{program.name}</h2>
                        <p>{program.description}</p>
                        <p>{program.details}</p>
                        {/* <div className='goal-raised'>
                <div className='goal'>
                    <p>Goal</p>
                    <p>50,000 $</p>
                </div>
                <div className='raised'>
                    <p>Raised</p>
                    <p>12,000 $</p>
                </div>
            </div>
<div className='donors-donated'>
<div  className='donors-card'>
<h3>102</h3>
<p>Donors</p>
</div>
<div className='donated-card'>
<h3>$ 5,000 </h3>
<p>Donated</p>
</div>
</div>
<div className="progress-bar-container">
                <div className="percentage">{program.progress}</div>
                <div className="progress-bar"><div className="progress" style={{ width: program.progress }}></div></div>
                
              
            </div> */}
                        <a href={program.link} target='_blank' className='donate-btn'>Donate now <img src='/cardIcons/arrow.svg' alt='arrow' /></a>

                    </div>

                </div>

            </div>
            <Footer />
        </div>
    )
}

export default ProgramDetails