import React from "react";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import '../componentStyles/subscriptionForm/subscription.css';
import "../i18n";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const SubscriptionForm = () => {
    const { t, i18n } = useTranslation();
    const [email, setEmail] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.success(t("homePage.formSection.successMessage"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setEmail('');


    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }
      useEffect(() => {
        i18n.changeLanguage(localStorage.getItem("selectedLanguage") || "en");
      }, [i18n]);
    
    return(
        <>
        <div className="subscription-form-container">
            <div className="subscription-form">
                <div className="subscribe">
                    <h3>{t("homePage.subscribeSection.titlebold")} <span>{t("homePage.subscribeSection.title")}</span></h3>
                    
                </div>
                <form onSubmit={handleSubmit}>
                    <input type="email" placeholder={t("homePage.subscribeSection.submit")} name="email" value={email} onChange={handleEmailChange} required />
                    <button type="submit">{t("homePage.subscribeSection.Subscribe")}</button>
                </form>
            </div>
        </div>
        <ToastContainer />
        
        </>
    )
}

export default SubscriptionForm;