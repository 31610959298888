import React, { useEffect } from "react";
import PageHeader from "../../componets/header";
import Footer from "../../componets/footer";
// import "../../styles/contactLayout/contactLayout.css";
import "../../styles/contact/contact.css";


import ContactCard from "../../componets/contactCard";
import SectionTitle from "../../componets/sectionTitle";
import { useTranslation } from "react-i18next";

const ContactPage = () => {
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    localStorage.setItem("selectedLanguage", lng);
  };
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("selectedLanguage") || "en");
  }, [i18n]);
  return (
    <div className="charity-contact">
      <PageHeader isDarkMode={false} />
      <div className="contact">
        <div className="contact-form">
          <div className="programs">
            {/* <div className="text"></div> */}
          </div>

          <div className="form-text">
            <div className="text">
              <SectionTitle
                title={t("homePage.contausSection.title")}
                subTitle={t("homePage.contausSection.subtitlebold")}
                subTitleSpan={t("homePage.contausSection.subtitle")}
              />
              <div className="our-program-title">
                <div className="paragraph">
                  {t("homePage.contausSection.description")}
                </div>
              </div>
            </div>

            <ContactCard />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ContactPage;
