import React from 'react'
import PageHeader from '../../componets/header'
import Footer from '../../componets/footer';
import "../../styles/ProgramDetails/programDetailLayout.css"

const DetailPageLayout = () => {
    return (
        <div className='charity-program-detail-layout'>
            <PageHeader />
            <div className="container">
                <div className='programs-title'>
                    {/* <div className="container"> */}
                    <div className='text'>
                        <div className="heading-span"></div>
                        <div className="paragraph-span"></div>
                        {/* </div> */}
                    </div>

                </div>

                <div className="details">
                    <div className="detail">
                        <div className="detail-image-text">
                            <div className="detail-image"></div>
                            <div className="detail-text"></div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default DetailPageLayout