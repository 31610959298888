import React from 'react'
import PageHeader from '../../componets/header'
import '../../styles/home/home_layout.css'
import Footer from '../../componets/footer'
import Home from '../../componets/home'

const HomePage = () => {
    return (
        <>
        <Home/>
        </>
    )
}

export default HomePage
