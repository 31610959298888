import React from "react";
import '../componentStyles/main.css'

const SectionTitle = ({title, subTitle, subTitleSpan}) => {
    return (
        <>
        <div className="section-title">
            <h2 className="section-heading">{title}</h2>
            <div className="sub-title">
                <h2>{subTitle} <span>{subTitleSpan}</span></h2>
            </div>
        </div>
            
        </>
        
        
    )

}

export default SectionTitle;