import React from 'react'
import PageHeader from '../componets/header'

const GalleryPage = () => {
    return (
        <div>
            <PageHeader initialDarkMode={false} />
            <div className="container gallery-page">
                <div className="images gallery mosaic-gallery">
                    <img src="/images/conference/DSC07289 copy.webp" alt="" />
                    <img src="/images/conference/DSC07295 copy.webp" alt="" />
                    <img src="/images/conference/DSC07304 copy.webp" alt="" />
                    <img src="/images/conference/DSC07306 copy.webp" alt="" />
                    <img src="/images/conference/DSC07315 copy.webp" alt="" />
                    <img src="/images/conference/DSC07323 copy.webp" alt="" />
                    <img src="/images/conference/DSC07327 copy.webp" alt="" />
                    <img src="/images/conference/DSC07332 copy.webp" alt="" />
                    <img src="/images/conference/DSC07338 copy.webp" alt="" />
                    <img src="/images/conference/DSC07339 copy.webp" alt="" />
                    <img src="/images/conference/DSC07341 copy.webp" alt="" />
                    <img src="/images/conference/MKP01254 copy.webp" alt="" />
                    <img src="/images/conference/MKP01262 copy.webp" alt="" />
                    <img src="/images/conference/MKP01265 copy.webp" alt="" />
                    <img src="/images/conference/MKP01271 copy.webp" alt="" />
                    <img src="/images/conference/MKP01283 copy.webp" alt="" />
                    <img src="/images/conference/MKP01287 copy.webp" alt="" />
                    <img src="/images/conference/MKP01294 copy.webp" alt="" />
                    <img src="/images/conference/MKP01295 copy.webp" alt="" />
                    <img src="/images/conference/MKP01298 copy.webp" alt="" />
                    <img src="/images/conference/MKP01301 copy.webp" alt="" />
                    <img src="/images/conference/MKP01313 copy.webp" alt="" />
                    <img src="/images/conference/MKP01329 copy.webp" alt="" />
                    <img src="/images/conference/MKP01336 copy.webp" alt="" />
                    <img src="/images/conference/MKP01342 copy.webp" alt="" />
                    <img src="/images/conference/MKP01345 copy.webp" alt="" />
                    <img src="/images/conference/MKP01360 copy.webp" alt="" />
                    <img src="/images/conference/MKP01361 copy.webp" alt="" />
                    <img src="/images/conference/MKP01372 copy.webp" alt="" />
                    <img src="/images/conference/MKP01379 copy.webp" alt="" />
                    <img src="/images/conference/MKP01391 copy.webp" alt="" />
                    <img src="/images/conference/MKP01395 copy.webp" alt="" />
                    <img src="/images/conference/MKP01401 copy.webp" alt="" />
                    <img src="/images/conference/MKP01413 copy.webp" alt="" />
                    <img src="/images/conference/MKP01421 copy.webp" alt="" />
                    <img src="/images/conference/MKP01443 copy.webp" alt="" />
                    <img src="/images/conference/MKP01445 copy.webp" alt="" />
                    <img src="/images/conference/MKP01468 copy.webp" alt="" />
                    <img src="/images/conference/MKP01474 copy.webp" alt="" />
                    <img src="/images/conference/MKP01493 copy.webp" alt="" />
                </div>

            </div>
        </div>
    )
}

export default GalleryPage