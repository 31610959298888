import React, { useEffect, useState } from 'react'
import PageHeader from '../../componets/header'
import '../../styles/programPage/programs.css'
import Footer from '../../componets/footer'
import { ArrowRight02Icon } from 'hugeicons-react'
import ProgramCard from '../../componets/programCard'
import { useTranslation } from "react-i18next";
import '../../i18n';
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import SlicedText from '../../componets/slicedText'

const ProgramsPage = ({ value, max }) => {
  const api = process.env.REACT_APP_API_URL
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  // programs
  const [programs, setPrograms] = useState([])
  const [englishPrograms, setEnglishPrograms] = useState([])
  const [frenchPrograms, setFrenchPrograms] = useState([])
  const [gettingPrograms, setGettingPrograms] = useState([])
  useEffect(() => {
    // get current language
    if (localStorage.getItem("selectedLanguage") === 'en') {
      setPrograms(englishPrograms)
    } else {
      setPrograms(frenchPrograms)
    }

  })
  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(`${api}/programs/area/canada/`);
        console.log('Getting programs...');
        if (response.status === 200) {
          setFrenchPrograms(response.data.french)
          setEnglishPrograms(response.data.english)
          console.log('Finished ', response.data)
          setGettingPrograms(false)
        }
      } catch (error) {
        setGettingPrograms(false)
        console.log('Error fetching programs:', error);
      }
    }
    fetchPrograms()
  }, [])

  const handleViewDetails = (program) => {
    navigate(`/programs/${program.id}`, { state: program });
  }

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("selectedLanguage") || "en");
  }, [i18n]);

  return (
    <div className='program-page'>
      <PageHeader isDarkMode={false} />
      <div className='program-layout'>
        <div className='programs'>
          <div className='text'>
            <h3><span>{t("homePage.ourProgramsSection.subtitlebold")}{" "}</span>{t("homePage.ourProgramsSection.subtitle")}</h3>
            <p>{t("homePage.ourProgramsSection.title")}</p>
          </div>
        </div>
        <div className='program-cards'>
          <div className='cards'>
            {programs.map((program, index) => (
              <ProgramCard
                key={program.id}
                id={program.id}
                title={program.name}
                description={<SlicedText text={program.description} length={150} />}
                progress={program.progress}
                onViewDetails={() => handleViewDetails(program)}
                image={program.image}
                link={program.link}
                details={program.details}

              />
            ))}

          </div>

        </div>
      </div>

      <Footer />
    </div>

  )
}

export default ProgramsPage