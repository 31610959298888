import React from 'react'

const SlicedText = ({ text, length }) => {
    // slice text
    const slicedText = text.slice(0, length);


    return (
        <span>{slicedText}...</span>
    )
}

export default SlicedText
