import React from 'react';
import { useState, useEffect } from 'react';
import '../componentStyles/contactCard/contact.css';
import { useTranslation } from "react-i18next";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ContactCard = () => {

    const { t, i18n } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        message: ''
    });
  const changeLanguage = (lng) => {
    localStorage.setItem("selectedLanguage", lng);
  };
  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem("selectedLanguage") || "en");
  }, [i18n]);

  const handleInputChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value });
}

    const handleSubmit = (e) => {
        e.preventDefault();
        toast.success(t("homePage.formSection.successMessage"), {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setFormData({
            name: '',
            email: '',
            phone: '',
            message: ''
        });
    };
    return(
        <>
            <div className="contact-card-container">
                <div className="contact-card">
                    <form onSubmit={handleSubmit}>
                        <input type="text" name='name' placeholder={t("homePage.formSection.name")} required className="name-input" value={formData.name} onChange={handleInputChange}/>
                        <div className="email-and-tel">
                            <input type="email" name='email' placeholder={t("homePage.formSection.email")} required value={formData.email} onChange={handleInputChange}/>
                            <input id="tel" type="tel" name='phone' placeholder={t("homePage.formSection.phone")} value={formData.phone} onChange={handleInputChange}  required/>
                        </div>
                        <textarea name="message" id="" value={formData.message} onChange={handleInputChange} placeholder={t("homePage.formSection.description")}></textarea>
                        <button type="submit">{t("homePage.formSection.buttontext")}</button>
                    </form>
                </div>
            </div>
            <ToastContainer />
        
        </>
    )
}

export default ContactCard;