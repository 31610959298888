export const API_URL = process.env.REACT_APP_API_URL


export const formatDate = (dateString) => {
    if (!dateString) return '';

    // Use the substring method to format the date string without parsing it
    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const day = dateString.substring(8, 10);
    const hours = dateString.substring(11, 13);
    const minutes = dateString.substring(14, 16);

    // Determine AM/PM
    const period = parseInt(hours) >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    const formattedHours = (parseInt(hours) % 12 || 12).toString().padStart(2, '0');

    return `${year}-${month}-${day} ${formattedHours}:${minutes} ${period}`;
};
